@import url('https://use.typekit.net/tnr6vxu.css');

.printHeader {
  visibility: hidden;
  height: 0;
  width: 100%;
}
.watermark{
  visibility: hidden;

}

@page {
  size: A4;
  width: 100% !important;
  height: 100% !important;
}
/* @page {
  margin-left: 5%;
  margin-right:5%;
} */
@media print {

  /* table .table thead {
    display: table-row-group !important;
  } */
  
  html,
  body {
    width: 100% !important;
    overflow: initial !important;
    font: 10pt, Helvetica, Arial, Verdana, sans-serif !important;
    color: #000000 !important;
    background: none;
    page-break-after: avoid !important;
    page-break-inside: avoid !important;
    page-break-before: avoid !important;
  }

  table, td, th {
    color: #000 !important;
  }

  h1 {
    font-size: 20pt;
  }

  h2, h3, h4 {
    font-size: 12pt;
  }

  a, blockquote, pre,
  article a[href^="#"]:after {
    page-break-inside: avoid;
  }

  h1, h2, h3, h4, h5, h6, img {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  dl, ol, ul {
    page-break-before: avoid;
  }

  a, a:link, a:visited {
    background: 0 0;
    color: #520;
    font-weight: 700;
    text-decoration: underline;
    text-align: left;
  }

  a[href^=http]:after {
    content: " < "attr(href) "> ";
  }

  a:after>img {
    content: "";
  }

  .section {
    padding-top: 0;
  }

  * {
    page-break-after: avoid;
    page-break-before: avoid;
  }

  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .column.is-narrow,
  .column.is-narrow~.column {
    display: inline-block;
  }

  .print {
    display: block !important;
  }

  .printHeader {
    visibility: visible;
    height: auto;
    display: table-header-group !important;
    table-layout: fixed;
    min-width: 100%;
    top: 0;
  }

  .no-print {
    display: none;
  }

  .column.is-6 {
    max-width: 50%;
  }

  .columns:last-child,
  .field:not(:last-child) {
    margin: 0 !important;
  }


  #root {
    margin: 0 !important;
    position: relative !important;
    padding-top: 0 !important;
  }
}

@media print {

  .custom-radio input:checked~.radiomark {
    background-color: #263f62 !important;
    border: 1px solid #263f62 !important;
    width: 19px !important;
    height: 19px !important;
    border-radius: 50% !important;
    /* -webkit-print-color-adjust: exact;
    print-color-adjust: exact; */
  }

  .custom-check input:checked~.checkmark {
    background-color: #263f62 !important;
    border: 1px solid #263f62 !important;
    width: 19px !important;
    height: 19px !important;
    /* -webkit-print-color-adjust: exact;
    print-color-adjust: exact; */
  }

  .auth-check, .auth-check .icon {
    padding: 0 !important;
    height: 20px !important;
    width: 20px !important;
    /* -webkit-print-color-adjust: exact;
    print-color-adjust: exact; */
  }

  .accordion {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  /* 
  .accordion, .button {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  } */

  .__react_component_tooltip, .expando-toggle-icon,
  .button.is-outlined>.icon {
    display: none;
  }

}


@media print {
  /* 
  .coa-certificate {
    padding-top: 1mm;
    padding-right: 1mm;
    padding-bottom: 3mm;
    margin-bottom: 3mm;
    padding-left: 1mm;
  } */

  .print-border {
    border-width: 3px;
    border-style: solid;
    border-color: #729eae;
    border-radius: 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
  }

  .coaPrint {
    position: fixed;
    bottom: 0;
    width: 90%;
  }

  .message.is-light .message-header {
    background-color: #e0edef !important;
    color: #000 !important;
    border: 1px solid #5b7f862e;
    text-transform: uppercase;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .custom-bordered {
    border: 1px solid #e0edef !important;
  }

  table td>span.tag {
    display: none !important;
  }


  .report-data thead {
    display: table-header-group;
    margin-top: 80px;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .inner-table thead {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
.table.inner-table td {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

  .page-footer, .page-header {
    position: fixed;
    width: 100%;
  }

  .page-footer {
    bottom: 0;
    height: 30px;
  }

  .page-header {
    top: 0;
  }

  .extra-spacing-tr {
    height: 110px;
  }
  .watermark {
    visibility:visible;
    position: fixed;
    bottom: 10px;
    right: 10px;
    /* transform: rotate(-30deg); */
    text-align: center;
    z-index: 5; /* Ensure it is behind other content */
  }
  .watermark img{
    opacity: 0.7;
  }

  /* 
  .coa-certificate thead:not(:first-of-type) {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  } */
  /* 
  .coa-certificate thead td, .coa-certificate thead th {
    color: #000 !important;
  } */

  hr:not(.hr) {
    background: 0;
    margin-top: 0;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  .column.is-full-print {
    flex: none;
    width: 100%;
  }

  /* 
  .button.is-outlined.is-info, .tag {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  } */

  .sub-title, .title {
    font-size: 22px !important;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
  }

  .printfooter {
    display: table;
  }

  .tableHeaderGroup {
    display: table-header-group;
  }

  .tableRowGroup {
    display: table-row-group;
  }
  .tablerepeat{
    display: table-footer-group;

  }

  .tableFooterGroup {
    display: table-footer-group;
    bottom: 0;
  }

  tfoot {
    display: table-footer-group;
    bottom: 0;
  }

  thead {
    display: table-header-group;
    top: 0;
  }

  thead {
    break-inside: avoid;
    page-break-inside: avoid;
    break-after: avoid;
    page-break-after: avoid;
    break-before: avoid;
    page-break-before: avoid;
    height: auto;
  }

  td {
    break-inside: avoid;
    page-break-inside: avoid;
    break-after: avoid;
    page-break-after: avoid;
    break-before: avoid;
    page-break-before: avoid;
  }

  table {
    break-after: avoid;
    page-break-after: avoid;
    break-before: avoid;
    page-break-before: avoid;
  }

  .pageBreakAvoid {
    break-inside: avoid;
    page-break-inside: avoid;
    break-after: avoid;
    page-break-after: avoid;
    break-before: avoid;
    page-break-before: avoid;
  }
}

/* label Print */
@media print {
  
  .print-break {
    page-break-after: always;
  }

  table, td, th {
    color: #000 !important;
  }

  h1 {
    font-size: 16pt;
  }

  h2, h3, h4 {
    font-size: 12pt;
  }

  a, article a[href^="#"]:after, blockquote, pre {
    page-break-inside: avoid;
  }

  h1, h2, h3, h4, h5, h6, img {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  dl, ol, ul {
    page-break-before: avoid;
  }

  a, a:link, a:visited {
    background: 0 0;
    color: #520;
    font-weight: 700;
    text-decoration: underline;
    text-align: left;
  }

  a[href^=http]:after {
    content: " < "attr(href) "> ";
  }

  a:after>img {
    content: "";
  }

  .section {
    padding-top: 0;
  }

  .column.is-narrow, .column.is-narrow~.column {
    display: inline-block;
  }

  .column.is-6 {
    max-width: 50%;
  }

  .columns:last-child, .field:not(:last-child) {
    margin: 0 !important;
  }
}

.label-container {
  border-color: #333;
  margin: 2mm;
  font-family: courier-std, monospace !important;
  font-weight: 400;
  font-size: 3mm;
  color: #000;
  padding: 2.5mm;
  line-height: normal;
}


.pd-1 {
  padding: .1mm .6mm;
}

.pd-2 {
  padding: 2mm;
}

.label-row {
  display: flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: -webkit-flex;
  flex-direction: row;
  line-height: normal;
}

.label-column {
  flex-grow: 1;
}

.direction-column {
  flex-direction: row !important;
  justify-content: space-between;
}

.direction-row {
  flex-direction: column !important;
  justify-content: space-between;
}

.direction-row>div {
  padding: 1mm 2mm !important;
}

.is-half {
  width: 50%;
}

.label-flex-grow-0 {
  flex-grow: 0;
}

.is-wrap {
  flex-wrap: wrap;
}

.is-nowrap {
  flex-wrap: nowrap;
}

.table.label-container td {
  display: block;
}

.table.label-container tr td {
  align-items: flex-start;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
@media print {
  table {
    width: 100%;
    border-collapse: collapse;
  }
  .table{
    width: 100% !important;
  }
  .css-1hb7zxy-IndicatorsContainer{
    display: none;
  }
}
@media print {
  .tableFooterGroup{
    visibility: visible;
  }
  .Assnment{
    margin-top: 17px;
  }
  #Assnment{
    margin-top: 17px;
  }
  .css-1hb7zxy-IndicatorsContainer{
    display: none;
  }
}